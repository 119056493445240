import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e9f5451 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _e8b62cda = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _36a7fd34 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1a5622e4 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _0466f71e = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _4a8dea4c = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _5a4a9f96 = () => interopDefault(import('../pages/gallery/index.vue' /* webpackChunkName: "pages/gallery/index" */))
const _f4f005a6 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1b19f001 = () => interopDefault(import('../pages/lokasi-toko/index.vue' /* webpackChunkName: "pages/lokasi-toko/index" */))
const _6e0712cf = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _8b5b015a = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _2b368298 = () => interopDefault(import('../pages/admin/blog/index.vue' /* webpackChunkName: "pages/admin/blog/index" */))
const _469594e0 = () => interopDefault(import('../pages/admin/category/index.vue' /* webpackChunkName: "pages/admin/category/index" */))
const _550ff164 = () => interopDefault(import('../pages/admin/contact/index.vue' /* webpackChunkName: "pages/admin/contact/index" */))
const _6f7b34c4 = () => interopDefault(import('../pages/admin/event/index.vue' /* webpackChunkName: "pages/admin/event/index" */))
const _d5f723d4 = () => interopDefault(import('../pages/admin/gallery/index.vue' /* webpackChunkName: "pages/admin/gallery/index" */))
const _6e3cd10c = () => interopDefault(import('../pages/admin/hero/index.vue' /* webpackChunkName: "pages/admin/hero/index" */))
const _314b5f87 = () => interopDefault(import('../pages/admin/location/index.vue' /* webpackChunkName: "pages/admin/location/index" */))
const _0235a14f = () => interopDefault(import('../pages/admin/marketplace/index.vue' /* webpackChunkName: "pages/admin/marketplace/index" */))
const _7e2dedd3 = () => interopDefault(import('../pages/admin/promo/index.vue' /* webpackChunkName: "pages/admin/promo/index" */))
const _ec313aec = () => interopDefault(import('../pages/admin/blog/create.vue' /* webpackChunkName: "pages/admin/blog/create" */))
const _3cb471a4 = () => interopDefault(import('../pages/admin/category/create.vue' /* webpackChunkName: "pages/admin/category/create" */))
const _67be98e0 = () => interopDefault(import('../pages/admin/event/create.vue' /* webpackChunkName: "pages/admin/event/create" */))
const _333d9f68 = () => interopDefault(import('../pages/admin/gallery/create.vue' /* webpackChunkName: "pages/admin/gallery/create" */))
const _fddcc99c = () => interopDefault(import('../pages/admin/hero/create.vue' /* webpackChunkName: "pages/admin/hero/create" */))
const _1fd4dc17 = () => interopDefault(import('../pages/admin/location/create.vue' /* webpackChunkName: "pages/admin/location/create" */))
const _6c32d34f = () => interopDefault(import('../pages/admin/marketplace/create.vue' /* webpackChunkName: "pages/admin/marketplace/create" */))
const _27260d60 = () => interopDefault(import('../pages/admin/promo/category/index.vue' /* webpackChunkName: "pages/admin/promo/category/index" */))
const _6f44174b = () => interopDefault(import('../pages/admin/promo/create.vue' /* webpackChunkName: "pages/admin/promo/create" */))
const _41051e6f = () => interopDefault(import('../pages/admin/event/peserta/_id.vue' /* webpackChunkName: "pages/admin/event/peserta/_id" */))
const _3a890fb8 = () => interopDefault(import('../pages/admin/promo/category/_id.vue' /* webpackChunkName: "pages/admin/promo/category/_id" */))
const _0e975f9f = () => interopDefault(import('../pages/admin/blog/_id/index.vue' /* webpackChunkName: "pages/admin/blog/_id/index" */))
const _384def0a = () => interopDefault(import('../pages/admin/category/_id/index.vue' /* webpackChunkName: "pages/admin/category/_id/index" */))
const _541a0668 = () => interopDefault(import('../pages/admin/contact/_id.vue' /* webpackChunkName: "pages/admin/contact/_id" */))
const _33d8fcee = () => interopDefault(import('../pages/admin/event/_id/index.vue' /* webpackChunkName: "pages/admin/event/_id/index" */))
const _0da6e3fe = () => interopDefault(import('../pages/admin/gallery/_id/index.vue' /* webpackChunkName: "pages/admin/gallery/_id/index" */))
const _2e22f412 = () => interopDefault(import('../pages/admin/hero/_id/index.vue' /* webpackChunkName: "pages/admin/hero/_id/index" */))
const _4ccb5ef2 = () => interopDefault(import('../pages/admin/location/_id/index.vue' /* webpackChunkName: "pages/admin/location/_id/index" */))
const _37af7cba = () => interopDefault(import('../pages/admin/marketplace/_id/index.vue' /* webpackChunkName: "pages/admin/marketplace/_id/index" */))
const _2de5e73e = () => interopDefault(import('../pages/admin/promo/_id/index.vue' /* webpackChunkName: "pages/admin/promo/_id/index" */))
const _34f15bec = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _06c2b84e = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _cd085be6 = () => interopDefault(import('../pages/event/_slug/form.vue' /* webpackChunkName: "pages/event/_slug/form" */))
const _c616c9da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7e9f5451,
    name: "about"
  }, {
    path: "/admin",
    component: _e8b62cda,
    name: "admin"
  }, {
    path: "/blog",
    component: _36a7fd34,
    name: "blog"
  }, {
    path: "/contact",
    component: _1a5622e4,
    name: "contact"
  }, {
    path: "/event",
    component: _0466f71e,
    name: "event"
  }, {
    path: "/faq",
    component: _4a8dea4c,
    name: "faq"
  }, {
    path: "/gallery",
    component: _5a4a9f96,
    name: "gallery"
  }, {
    path: "/login",
    component: _f4f005a6,
    name: "login"
  }, {
    path: "/lokasi-toko",
    component: _1b19f001,
    name: "lokasi-toko"
  }, {
    path: "/marketplace",
    component: _6e0712cf,
    name: "marketplace"
  }, {
    path: "/promo",
    component: _8b5b015a,
    name: "promo"
  }, {
    path: "/admin/blog",
    component: _2b368298,
    name: "admin-blog"
  }, {
    path: "/admin/category",
    component: _469594e0,
    name: "admin-category"
  }, {
    path: "/admin/contact",
    component: _550ff164,
    name: "admin-contact"
  }, {
    path: "/admin/event",
    component: _6f7b34c4,
    name: "admin-event"
  }, {
    path: "/admin/gallery",
    component: _d5f723d4,
    name: "admin-gallery"
  }, {
    path: "/admin/hero",
    component: _6e3cd10c,
    name: "admin-hero"
  }, {
    path: "/admin/location",
    component: _314b5f87,
    name: "admin-location"
  }, {
    path: "/admin/marketplace",
    component: _0235a14f,
    name: "admin-marketplace"
  }, {
    path: "/admin/promo",
    component: _7e2dedd3,
    name: "admin-promo"
  }, {
    path: "/admin/blog/create",
    component: _ec313aec,
    name: "admin-blog-create"
  }, {
    path: "/admin/category/create",
    component: _3cb471a4,
    name: "admin-category-create"
  }, {
    path: "/admin/event/create",
    component: _67be98e0,
    name: "admin-event-create"
  }, {
    path: "/admin/gallery/create",
    component: _333d9f68,
    name: "admin-gallery-create"
  }, {
    path: "/admin/hero/create",
    component: _fddcc99c,
    name: "admin-hero-create"
  }, {
    path: "/admin/location/create",
    component: _1fd4dc17,
    name: "admin-location-create"
  }, {
    path: "/admin/marketplace/create",
    component: _6c32d34f,
    name: "admin-marketplace-create"
  }, {
    path: "/admin/promo/category",
    component: _27260d60,
    name: "admin-promo-category"
  }, {
    path: "/admin/promo/create",
    component: _6f44174b,
    name: "admin-promo-create"
  }, {
    path: "/admin/event/peserta/:id?",
    component: _41051e6f,
    name: "admin-event-peserta-id"
  }, {
    path: "/admin/promo/category/:id",
    component: _3a890fb8,
    name: "admin-promo-category-id"
  }, {
    path: "/admin/blog/:id",
    component: _0e975f9f,
    name: "admin-blog-id"
  }, {
    path: "/admin/category/:id",
    component: _384def0a,
    name: "admin-category-id"
  }, {
    path: "/admin/contact/:id",
    component: _541a0668,
    name: "admin-contact-id"
  }, {
    path: "/admin/event/:id",
    component: _33d8fcee,
    name: "admin-event-id"
  }, {
    path: "/admin/gallery/:id",
    component: _0da6e3fe,
    name: "admin-gallery-id"
  }, {
    path: "/admin/hero/:id",
    component: _2e22f412,
    name: "admin-hero-id"
  }, {
    path: "/admin/location/:id",
    component: _4ccb5ef2,
    name: "admin-location-id"
  }, {
    path: "/admin/marketplace/:id",
    component: _37af7cba,
    name: "admin-marketplace-id"
  }, {
    path: "/admin/promo/:id",
    component: _2de5e73e,
    name: "admin-promo-id"
  }, {
    path: "/blog/:slug",
    component: _34f15bec,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _06c2b84e,
    name: "event-slug"
  }, {
    path: "/event/:slug/form",
    component: _cd085be6,
    name: "event-slug-form"
  }, {
    path: "/",
    component: _c616c9da,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
