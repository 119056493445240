//
//
//
//
//
//
//
//

import AdminSidebar from './admin-sidebar.vue'
export default {
  components: {
    AdminSidebar
  }
}
