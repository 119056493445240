const state = () => ({
  idEvent: null
})

const getters = {
  idEvent: state => state.idEvent
}

const mutations = {
  // eslint-disable-next-line space-before-function-paren
  SET_ID_EVENT(state, payload) {
    state.idEvent = payload
  }
}

const actions = {
  // eslint-disable-next-line space-before-function-paren
  setIdEvent({ commit }, val) {
    commit('SET_ID_EVENT', val)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
