//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data: () => ({
    show: false
  }),
  computed: {
    showed () {
      let tempWidth = null
      if (process.client) {
        tempWidth = window.innerWidth
      }
      return tempWidth
    }
  },
  watch: {
  },
  destroyed () {
  },
  methods: {
    showMenu () {
      this.show = !this.show
    },
    showOutside () {
      this.show = false
    },
    goToCentury () {
      this.$router.push({ name: 'centurynet' })
    }
  }
}
